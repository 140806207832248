<template>
  <div style="width: 100%">
    <div class="quote-contact-box">
      <div v-for="(groupItem, index) in data" :key="index">
        <div class="spacer"></div>
        <div class="form-group">
          <div class="form-group__item" v-for="item in groupItem.formGroup" :key="item.name">
            <v-base-input
              class="base-input--quote"
              :name="item.name"
              :input-class-name="'quote-input'"
              :is-required="item.required"
              :placeholder="item.placeholder"
              :error-message="validateForm[item.name].$model ? item.errorMessage.error : item.errorMessage.empty"
              :showError="validateForm ? validateForm[item.name].$anyError : false"
              @input-change="onInputChange">
            </v-base-input>
          </div>
        </div>
      </div>
    </div>
    <div class="quote-details">
    <p class="quote-contact-box__text">Give us some details</p>
    <textarea
      name="information-textarea"
      placeholder="Leave any information you consider necessary for our understanding of your request"
      class="quote-details-text"
      @change="onTextareaChange($event.target.value)">
    </textarea>
      <v-file-uploader @on-file-uploaded="onFileUploaded"></v-file-uploader>
    </div>
    <v-checkbox
      class="quote-contact-box__checkbox"
      :name="'termsOfUseAccepted'"
      :is-required="true"
      :showError="showCheckboxError && !validateForm.termsOfUseAccepted.$model"
      :checkbox-text="checkboxText"
      @checkbox-change="onCheckboxChange">
    </v-checkbox>
  </div>

</template>

<script>
import FileUploader from "@/components/File-uploader"
import {email, required, sameAs} from "vuelidate/lib/validators";
import BaseInput from "@/components/Base-input";
import Checkbox from "@/components/Checkbox";
import {eventBus} from "@/main";
export default {
  name: "Step-3",
  props: {
    data: Array
  },
  data() {
    return {
      message: '',
      files: [],
      checkboxText: '<span>I accept&nbsp;</span><a href="#" target="_blank" rel="nofollow" class="checkbox-text-holder__link">Terms of Use</a><span>&nbsp;and&nbsp;</span><a href="#" target="_blank" rel="nofollow" class="checkbox-text-holder__link">Privacy Notice</a>',
      showCheckboxError: false,
      quoteForm: {
        name: '',
        email: '',
        phone: '',
        skype: '',
        socialLink: '',
        termsOfUseAccepted: false
      },
    }
  },
  validations: {
    quoteForm: {
      name: {
        required,
      },
      email: {
        required,
        email
      },
      phone: '',
      skype: '',
      socialLink: '',
      termsOfUseAccepted: {
        sameAs: sameAs(() => true)
      }
    },
  },
  computed: {
    validateForm() {
      return this.$v.quoteForm || false
    }
  },
  components: {
    'v-file-uploader': FileUploader,
    'v-base-input': BaseInput,
    'v-checkbox': Checkbox
  },
  methods: {
    onTextareaChange(value) {
      this.message = value;
      this.$emit('step-result', {key: 'message', value})
    },
    onFileUploaded(value) {
      this.files = value;
      const requestFiles = this.files.map(item => ({file: item.file}));
      this.$emit('step-result', {key: 'files_attributes', value: requestFiles})
    },
    onInputChange(data) {
      //data = {value, name}
      this.quoteForm[data.name] = data.value;
      this.$emit('step-result', {key: data.name, value: data.value})
    },
    onCheckboxChange(value) {
      this.quoteForm.termsOfUseAccepted = value
      this.$emit('step-result', {key: 'termsOfUseAccepted', value})
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.validateForm.termsOfUseAccepted.$model) {
        this.showCheckboxError = true
      }
      if (this.$v.$invalid) {
        return false;
      }
    }
  },
  mounted() {
    eventBus.$on('validateForm', this.onSubmit)
  }
}
</script>

<style lang="scss">
.quote-details {
  width: 100%;
}
.quote-details-text {
  display: block;
  width: 100%;
  height: 68px;
  padding: 10px 15px;
  background-color: white;
  color: #000;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: 24px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  box-shadow: none;
  border: none;

  @media (max-width: $viewport-mobile) {
      font-size: .8rem;
    }

  &::placeholder {
    font-family: "Montserrat", sans-serif;
    font-weight: normal;
    color: rgba(#000, .5);
  }
}
.quote-contact-box {
  width: 100%;

    .spacer {
        margin-bottom: 15px;
    }
}

.quote-contact-box__text {
  display: block;
  width: 100%;
  margin: 0 0 15px;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.625;

  @media (max-width: $viewport-mobile) {
    margin-top: 13px;
  }
}

.quote-contact-box__checkbox {
  margin: 15px 0;
}
</style>

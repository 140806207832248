<template>
  <div class="base-input"
       :class="{'base-input--error': showError}">
    <label class="base-input__data">
      <input class="base-input__value"
        :name="name"
        :type="type"
        :class="inputClassName"
        :placeholder="placeholder + labelNote"
        @change="$emit('input-change', {value : $event.target.value, name})">
      <span class="base-input__message" v-if="errorMessage && showError">{{ errorMessage }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "Base-input",
  props: {
    label: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    errorMessage: String,
    placeholder: String,
    isRequired: Boolean,
    inputClassName: String,
    showError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    labelNote() {
      return this.isRequired ? ' * ' : ' (optional) '
    }
  }
}
</script>

<style lang="scss">
.base-input {
  display: block;
}

.base-input__data {
  position: relative;
  display: block;
}

.base-input--error {

  .base-input__value {
    border-color: var(--primary-red-color);

    &:focus {
      border-color: var(--primary-red-color);
    }
  }

  .base-input__message {
    display: block;
  }
}

.base-input--ok {

  .base-input__value {
    border-color: var(--primary-green-color);

    &:focus {
      border-color: var(--primary-green-color);
    }
  }
}

.base-input--ok {

  .base-input__value {
    border-color: var(--primary-green-color);
  }

  .base-input__message {
    display: block;
  }
}

.base-input__value {
  display: block;
  width: 100%;
  padding: 11px 15px 11px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.19;
  color: var(--primary-dark-color);
  outline: none;
  border: 2px solid transparent;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  appearance: none;
  transition: border-color .2s ease-in-out;

  &::placeholder {
    color: var(--secondary-color);
    font-weight: normal;
    transition: color .2s ease-in-out;
  }

  &:hover {
    &::placeholder {
      color: var(--primary-dark-color);
    }
  }

  &:focus {
    border-color: rgba(0, 0, 0, 0.15);
  }
}

.base-input__message {
  display: none;
  margin-top: 0;
  color: var(--primary-red-color);
  font-size: .75rem;
  line-height: 1.67;
}
</style>

<template>
  <div class="file-uploader">
    <VueFileAgent
        ref="vueFileAgent"
        v-model="fileRecords"
        :theme="'list'"
        :multiple="true"
        :deletable="true"
        :meta="true"
        :accept="'image/*,.zip, .pdf, .doc, .docx, .xls, .xlsx'"
        :maxSize="'50MB'"
        :maxFiles="14"
        :errorText="{
          type: 'Invalid file type',
          size: 'Files should not exceed 50MB in size',
        }"
        @select="filesSelected($event)"
        @beforedelete="onBeforeDelete($event)"
        @delete="fileDeleted($event)">
      <template v-slot:file-preview-new>
        <div key="file-preview" class="file-uploader__preview">
          <v-dynamic-icon height="20" :icon="'file-uploader'" class="file-uploader__ico"></v-dynamic-icon>
          <div class="file-uploader__text">
            <span>Drag&amp;Drop your files here or </span>
            <span class="file-uploader__input-label">browse your files</span>
          </div>
        </div>

      </template>
    </VueFileAgent>
  </div>
</template>

<script>
export default {
  name: "File-uploader",
  data() {
    return {
      fileRecords: [],
      fileRecordsForUpload: [],
      uploadUrl: 'https://www.mocky.io/v2/5d4fb20b3000005c111099e3',
      uploadHeaders: {}
    }
  },
  methods: {
    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = [...this.fileRecordsForUpload, ...validFileRecords];
    },
    fileDeleted(fileRecord) {
      const index = this.fileRecordsForUpload.indexOf(fileRecord);
      if (index !== -1) {
        this.fileRecordsForUpload.splice(index, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    deleteUploadedFile(fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    onBeforeDelete(fileRecord){
      this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
    },
    onUpload() {
      this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload)
    }
  },
  watch: {
    fileRecords(value) {
      this.$emit('on-file-uploaded', value)
    }
  }
}
</script>

<style lang="scss">
.file-uploader {
  position: relative;
  margin-top: 25px;
  @media (max-width: $viewport-desktop-large) {
    margin-top: 20px;
  }
  @media (max-width: $viewport-mobile) {
    margin-top: 15px;
  }

  .vue-file-agent.file-input-wrapper {
    min-height: 100px;
    padding: 15px;
    border-color: #4176FA;
    background-color: #E4E7EE;
    box-sizing: border-box;

    & > div > div {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .grid-block-wrapper .file-preview-wrapper + .file-uploader__preview {
    margin-bottom: 35px;
  }

  .theme-list .vue-file-agent .file-preview-wrapper .file-preview::before {
    background: #F5F5F9;
  }

  .theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-ext,
  .theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-name,
  .theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-progress {
    color: var(--primary-dark-color);
  }

  .is-drag-over::after {
    content: 'Drop files here';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    padding: 25px;
    font-size: 1rem;
    font-weight: bold;
    box-sizing: border-box;
    transition: 0.2s ease-in-out;
    background-color: #4176fa;
    box-shadow: 0 0 0 0 rgba(65, 118, 250, 0.9);
    color: #fff;
    animation: pulse 2s infinite;
  }

  .is-drag-valid.is-drag-over .vue-file-agent.file-input-wrapper,
  .vue-file-agent.file-input-wrapper.is-drag-valid.is-drag-over {
    box-shadow: none;
    border-color: #4176FA;
  }

}

.file-uploader__text {
  color: #000;
  font-size: 1rem;
  text-align: center;
}

.file-uploader__input-label {
  position: relative;
  display: inline-block;
  color: #4176fa;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 1px solid rgba(65,118,250,.5);
  transition: border-bottom-color .2s ease-in-out;
}

.file-uploader__input-label:hover {
  border-bottom-color: transparent;
}

.file-uploader__input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.file-uploader__ico {
  width: 36px;
  height: 36px;
}

.file-uploader__item-title {
  display: block;
  flex-grow: 1;
  color: #000;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(65, 118, 250, 0.9);
  }
  70% {
    box-shadow: 0 0 0 13px rgba(65, 118, 250, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(65, 118, 250, 0);
  }
}
</style>

<template>
  <div class="quote-final">
    <div class="quote-final__text quote-final-text">
      <p class="quote-final-text__row">We've received your inquiry, and now one of our Business Drivers will take care of it.</p>
      <p class="quote-final-text__row">We'll get back to you in a few hours.</p>
      <p class="quote-final-text__row">ITernal loves you ❤</p>
    </div>
    <div class="quote-final__contact quote-final-contact">
      <p class="quote-final-contact__text">Having urgent requests, don't hesitate to contact us directly:</p>
      <img :src="require('@/assets/images/bondarchuk.jpg')" alt="Vitalii Bondarchuk" width="226" height="226" class="quote-final-contact__img">
      <p class="quote-final-contact__position">Business Development Director</p>
      <p class="quote-final-contact__row">
        <a href="tel:+380443334780" class="quote-final-contact__link">+380443334780</a>
      </p>
      <p class="quote-final-contact__row">
        <a href="mailto:vitalii.bondarchuk@iternal.group" class="quote-final-contact__link">vitalii.bondarchuk@iternal.group</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
name: "Step-5"
}
</script>

<style lang="scss">
.quote-final {
  display: flex;

  @media (max-width: $viewport-tablet-small) {
    flex-wrap: wrap;
  }
}

.quote-final__text {
  width: 50%;
  padding-right: 50px;
  box-sizing: border-box;

  @media (max-width: $viewport-tablet-small) {
    width: 100%;
    padding-right: 0;
  }
}

.quote-final-text {
  font-weight: normal;
  font-size: 1.6875rem;
  line-height: 1.675;

  @media (max-width: $viewport-tablet-small) {
    font-size: 1.375rem;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 1rem;
  }
}

.quote-final-text__row {
  margin-bottom: 50px;

  @media (max-width: $viewport-tablet-small) {
    margin-bottom: 30px;
  }
  @media (max-width: $viewport-mobile) {
    margin-bottom: 15px;
  }
}

.quote-final__contact {
  width: 50%;

  @media (max-width: $viewport-tablet-small) {
    width: 100%;
  }
}

.quote-final-contact {
  padding: 15px 30px 30px;
  background: #fff;
  box-sizing: border-box;
  @media (max-width: $viewport-mobile) {
    padding: 15px;
  }
}

.quote-final-contact__text {
  margin-bottom: 30px;
  @media (max-width: $viewport-mobile) {
    margin-bottom: 15px;
  }
}

.quote-final-contact__img {
  margin-bottom: 20px;
  @media (max-width: $viewport-mobile) {
    margin-bottom: 15px;
  }
}

.quote-final-contact__time {
  margin-bottom: 10px;
  font-size: 0.75rem;
}

.quote-final-contact__name {
  margin-bottom: 8px;
  font-family: Visuelt Pro;
  font-weight: 500;
  font-size: 2.125rem;

  @media (max-width: $viewport-mobile) {
    font-size: 1.625rem;
  }
}

.quote-final-contact__position {
  margin-bottom: 15px;
  font-size: 1.4375rem;

  @media (max-width: $viewport-mobile) {
    font-size: 1.0625rem;
  }
}

.quote-final-contact__row {
  display: block;
  margin-bottom: 5px;
}

.quote-final-contact__link {
  font-weight: bold;
  font-size: 0.875rem;
  color: rgba(0,0,0,0.5);
  border-bottom: 1px solid rgba(0,0,0,0.3);
  transition: border-bottom-color .2s ease-in-out;

  &:hover {
    border-bottom-color: transparent;
  }
}
</style>

<template>
  <label class="checkbox" :class="{'checkbox--error': showError}">
    <input
        class="checkbox__input"
        type="checkbox"
        :name="name"
        :required="isRequired"
        @change="$emit('checkbox-change', $event.target.checked)">
    <span class="checkbox__text-holder checkbox-text-holder" v-html="checkboxText"></span>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    name: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    text: String,
    showError: {
      type: Boolean,
      default: false
    },
    checkboxText: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.checkbox {
  position: relative;
  display: block;
  user-select: none;
  line-height: 30px;

  &--error {
    .checkbox__text-holder {
      color: #fb6262;
      &:after {
        border: 1px solid #fb6262;
      }

      .checkbox-text-holder__link {
        color: #fb6262;
        border-bottom: 1px solid #fb6262;
      }
    }
  }
}

.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.checkbox__input:checked + .checkbox__text-holder {
  &:after {
    content: '';
    border: 1px solid var(--primary-red-color);
    background: var(--primary-red-color) url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.63 1.873a.436.436 0 00-.619 0L5.49 8.365a.434.434 0 01-.62 0l-2.857-2.91a.434.434 0 00-.62 0L.13 6.59a.438.438 0 00.001.624l2.882 3.024c.17.173.45.453.62.624l1.238 1.25c.17.17.449.17.62 0l8.38-8.366a.445.445 0 000-.625l-1.24-1.248z' fill='%23F1F1F1'/%3E%3C/svg%3E") center no-repeat;
    background-size: 14px;
    box-sizing: border-box;
  }
}

.checkbox-text-holder {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding-left: 45px;
  line-height: 28px;
  cursor: pointer;

  @media (max-width: $viewport-mobile) {
    display: block;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    border: 1px solid #000;
    box-sizing: border-box;
  }

  .checkbox-text-holder__text {
    display: block;
    font-size: 1rem;
    box-sizing: border-box;
  }

  .checkbox-text-holder__link {
    font-size: 1rem;
    color: #000;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    transition: border-bottom-color 0.2s ease-in-out;

    &:hover {
      border-bottom-color: transparent;
    }
  }
}
</style>

<template>
  <div class="quote-help-type-list">
    <label class="quote-help-type-list-item-box" v-for="item in data" :key="item.id">
      <input type="checkbox" class="quote-help-type-list-item__input" @change="onCheckboxChecked(item)">
      <span class="quote-help-type-list-item">
      <span class="quote-help-type-list-item__title">{{ item.title }}</span>
      <v-dynamic-icon
          :icon="`quote-help-type-${item.imgName}`"
          class="quote-help-type-list-item__title-img">
      </v-dynamic-icon>
    </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "Step2",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      typeList: []
    }
  },
  methods: {
    onCheckboxChecked(item) {
      if (this.typeList.includes(item.id)) {
        this.typeList = this.typeList.filter(listItem => listItem !== item.id)
      } else {
        this.typeList.push(item.id);
      }
      this.$emit('step-result', { key: 'project_services', value: this.typeList })
    }
  },
  watch: {
    success() {
      if (this.success) {
        this.$emit('success-step');
      }
    }
  }
}
</script>

<style lang="scss">
.quote-help-type-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: -10px;

  @media (max-width: $viewport-mobile) {
    margin-top: -15px;
    margin-bottom: -20px;
  }
}

.quote-help-type-list-item-box {
  width: calc(50% - 5px);
  margin-right: 10px;
  margin-bottom: 10px;

  @media (max-width: $viewport-tablet-small) {
    width: 100%;
    margin-right: 0;
  }

  &:nth-child(2n) {
    margin-right: 0;
  }
}

.quote-help-type-list-item {
  display: block;
  min-height: 100%;
  padding: 45px 120px 45px 50px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color .2s ease-in-out, box-shadow .14s ease-in-out;

  @media (max-width: $viewport-desktop-large) {
    display: flex;
    align-items: center;
    padding: 35px 120px 30px 35px;
  }

  @media (max-width: $viewport-tablet-small) {
    padding-right: 90px;
  }

  @media (max-width: $viewport-mobile) {
    padding: 20px 60px 20px 20px;
  }

  &:hover {
    box-shadow: 0 0 0 3px inset #fff;

    .quote-help-type-list-item__link {
      opacity: 1;
    }
  }

  &--selected {
    background-color: #fff;

    .quote-help-type-list-item__link {
      opacity: 1;
    }
  }
}

.quote-help-type-list-item__input {
  position: absolute;
  clip: rect(0, 0, 0, 0);

  &:checked ~ .quote-help-type-list-item {
    background: #fff;

    .quote-help-type-list-item__link {
      opacity: 1;
    }
  }
}

.quote-help-type-list-item__title {
  position: relative;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1;

  @media (max-width: $viewport-tablet-small) {
    font-size: 1.75rem;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 1.25rem;
  }
}

.quote-help-type-list-item__title-img {
  position: absolute;
  width: 48px;
  height: 48px;
  top: 50%;
  right: 38px;
  transform: translateY(-50%);

  @media (max-width: $viewport-tablet-small) {
    width: 40px;
    height: 40px;
    right: 32px;
  }

  @media (max-width: $viewport-mobile) {
    width: 25px;
    height: 25px;
    right: 20px;
  }
}
</style>
